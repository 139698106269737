<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <Section grid="E" :bg-hero="product?.header?.content?.bg_media?.url" padding-y="py-16">
      <template #content>
        <div v-if="product?.header" class="lg:w-[620px]" style="position: relative">
          <NuxtImg
            class="w-56 mb-1"
            :src="product?.header?.logos[0]?.url || ''"
            :alt="product?.header?.logos[0]?.alternativeText || ''"
            loading="lazy"
          />
          <h1
            class="mb-5 mt-8 md:-mt-5 text-4xl lg:text-[56px] font-ubuntu font-bold lg:leading-[64px] text-white"
            v-text="product.header.content.title"
          />
          <div class="text-xl text-white mt-8 mb-10" v-html="product.header.content.description" />
          <component
            :is="product.header.links[0].url.includes('https') ? 'a' : resolveComponent('NuxtLink')"
            :to="`/${product.header.links[0].url}`"
            :href="product.header.links[0].url"
            class="btn"
            :class="product.header.links[0].type"
          >
            {{ product.header.links[0].label }}
          </component>
        </div>
      </template>
    </Section>
    <Section v-if="product?.features?.length" grid="E" padding-y="py-16">
      <template #content>
        <ToolsBanner
          v-for="(feature, index) in product.features"
          :key="index"
          :media="feature.media"
          :title="feature.title"
          :list="feature?.items"
          :icon-ext="feature.icon"
          bg-color-icon-ext="orange-arable"
          class-icon-list="text-gray"
          :reverse="index % 2 === 0"
          class="pt-6 lg:py-12 first:pt-0 last:pb-0"
        />
      </template>
    </Section>
    <Section
      v-if="product?.review"
      class="relative z-10 lg:bg-blue-medium"
      grid="G"
      padding-y="py-0"
    >
      <template #content-left>
        <div
          class="relative w-full lg:h-full lg:flex -mb-20 lg:mb-o z-10 aspect-w-16 aspect-h-9 lg:aspect-none lg:mr-5"
        >
          <NuxtImg
            class="h-full w-full lg:-my-5 lg:aspect-none object-cover"
            :src="product.review.media.url"
            :alt="product.review.media.alternativeText"
            loading="lazy"
          />
        </div>
      </template>
      <template #content-right>
        <div class="relative lg:py-14">
          <div
            class="relative h-full flex flex-col justify-center z-10 px-4 pt-20 pb-10 lg:p-0 -mx-4 lg:mx-0 xl:ml-5"
          >
            <blockquote class="flex flex-col text-2xl lg:text-3xl font-ubuntu font-bold text-white">
              <svg
                class="w-6 sm:w-8 h-6 sm:h-8 mb-4 sm:mb-6 self-start"
                width="23"
                height="17"
                viewBox="0 0 23 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.464 16.28C0.389334 15.7573 0.333333 15.216 0.296 14.656C0.258667 14.096 0.24 13.6853 0.24 13.424C0.24 10.9227 0.688 8.552 1.584 6.312C2.48 4.03466 3.58133 2.112 4.888 0.543999L10.544 1.832C9.83467 3.43733 9.256 5.26666 8.808 7.32C8.39733 9.336 8.192 11.2587 8.192 13.088C8.192 13.1627 8.192 13.3307 8.192 13.592C8.192 13.816 8.192 14.096 8.192 14.432C8.22933 14.7307 8.248 15.048 8.248 15.384C8.28533 15.72 8.32267 16.0187 8.36 16.28H0.464ZM12.504 16.28C12.4293 15.7573 12.3733 15.216 12.336 14.656C12.2987 14.096 12.28 13.6853 12.28 13.424C12.28 10.9227 12.728 8.552 13.624 6.312C14.52 4.03466 15.6213 2.112 16.928 0.543999L22.584 1.832C21.8747 3.43733 21.296 5.26666 20.848 7.32C20.4373 9.336 20.232 11.2587 20.232 13.088C20.232 13.1627 20.232 13.3307 20.232 13.592C20.232 13.816 20.232 14.096 20.232 14.432C20.2693 14.7307 20.288 15.048 20.288 15.384C20.3253 15.72 20.3627 16.0187 20.4 16.28H12.504Z"
                  fill="#109DC2"
                />
              </svg>
              <span v-text="product.review.content" />
              <svg
                class="w-6 sm:w-8 h-6 sm:h-8 self-end"
                width="24"
                height="17"
                viewBox="0 0 24 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.03431 16.28C0.959646 15.7573 0.903646 15.216 0.866313 14.656C0.828979 14.096 0.810313 13.6853 0.810313 13.424C0.810313 10.9227 1.25831 8.552 2.15431 6.312C3.05031 4.03466 4.15165 2.112 5.45831 0.543999L11.1143 1.832C10.405 3.43733 9.82631 5.26666 9.37831 7.32C8.96765 9.336 8.76231 11.2587 8.76231 13.088C8.76231 13.1627 8.76231 13.3307 8.76231 13.592C8.76231 13.816 8.76231 14.096 8.76231 14.432C8.79965 14.7307 8.81831 15.048 8.81831 15.384C8.85565 15.72 8.89298 16.0187 8.93031 16.28H1.03431ZM13.0743 16.28C12.9996 15.7573 12.9436 15.216 12.9063 14.656C12.869 14.096 12.8503 13.6853 12.8503 13.424C12.8503 10.9227 13.2983 8.552 14.1943 6.312C15.0903 4.03466 16.1916 2.112 17.4983 0.543999L23.1543 1.832C22.445 3.43733 21.8663 5.26666 21.4183 7.32C21.0076 9.336 20.8023 11.2587 20.8023 13.088C20.8023 13.1627 20.8023 13.3307 20.8023 13.592C20.8023 13.816 20.8023 14.096 20.8023 14.432C20.8396 14.7307 20.8583 15.048 20.8583 15.384C20.8956 15.72 20.933 16.0187 20.9703 16.28H13.0743Z"
                  fill="#109DC2"
                />
              </svg>
            </blockquote>
            <span
              class="block mb-2.5 font-bold font-ubuntu text-white"
              v-text="product.review.name"
            />
            <span class="text-white" v-text="product.review.responsibility" />
          </div>
          <div
            class="absolute w-screen h-full block lg:hidden top-0 left-1/2 -translate-x-1/2 bg-green-very-dark"
          />
        </div>
      </template>
    </Section>
    <Benefits
      v-if="product?.benefits"
      :title="product.benefits.header.title"
      :description="product.benefits.header.description"
      :list="product.benefits?.items"
      bg-icons="blue"
    />
    <Section
      v-if="product?.faqs?.items?.length"
      class="bg-blue-soft"
      padding-y="py-14 lg:pt-32 lg:pb-24"
      grid="E"
    >
      <template #header>
        <div
          class="flex flex-col items-center max-w-4xl mx-auto px-5 pb-12 lg:pb-20 text-center space-y-4"
        >
          <span
            class="font-ubuntu font-bold text-gray-dark text-3xl mb-6"
            v-text="product.faqs.title"
          />
        </div>
      </template>
      <template #content>
        <div class="flex justify-center">
          <Collapsible class="flex justify-center max-w-4xl" :list="product.faqs?.items" />
        </div>
      </template>
    </Section>
  </div>
</template>

<script setup>
import Section from '@/components/Sections';
import Benefits from '@/components/Benefits';
import ToolsBanner from '@/components/Banners/ToolsBanner';
import Collapsible from '@/components/Collapsible/CollapsibleMultiple';

const route = useRoute();
const store = useStore();
const { createHeadSeo } = useHeadSeo();
const { data } = await useAsyncData('actualidad', async () => {
  const product = await store.dispatch('strapi/getProducts', {
    product: route.params.slug,
    field: 'product'
  });
  return {
    product
  };
});
const product = computed(() => {
  const product = data.value.product;
  return {
    ...product,
    faqs: {
      ...product.faqs,
      items: product.faqs?.items.map(faq => ({ title: faq.question, content: faq.answer })) || []
    }
  };
});
watch(
  product,
  val => {
    const {
      meta: {
        title,
        description,
        og_image: { url }
      }
    } = val;
    createHeadSeo(title, description, url, val, STRUCTURED_DATA_TYPE.PRODUCT);
  },
  { immediate: true, deep: true }
);
</script>
