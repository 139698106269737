<template>
  <div>
    <div class="pt-collapsible box" role="presentation">
      <div
        class="divide-y divide-gray-very-light border-gray-very-light first:border-t last:border-b"
      >
        <dl v-for="(item, index) in list" :key="index" :group-id="index">
          <dt>
            <button
              class="flex justify-between w-full py-4 lg:p-6 items-center"
              :aria-expanded="expandedId === index"
              type="button"
              @click="toggleState(index)"
            >
              <h3 class="font-ubuntu font-bold text-lg text-gray-dark">{{ item.title }}</h3>
              <icon
                class="text-blue text-3xl lg:ml-14"
                :name="expandedId === index ? 'pt-angle-up' : 'pt-angle-down'"
                aria-hidden="true"
              />
            </button>
          </dt>
          <dd
            :id="`${index}-content`"
            class="pt-collapsible__content"
            :role="`${index}-region`"
            :class="{ 'pt-expanded': expandedId === index }"
          >
            <markdown
              v-if="item.content"
              class="text-gray text-base px-4 pb-8 pl-7"
              :content="item.content"
            />
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import Markdown from '@/components/Markdown';
export default {
  name: 'CollapsibleMultiple',
  components: {
    Markdown
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      expandedId: undefined
    };
  },
  methods: {
    toggleState(id) {
      if (this.expandedId === id) {
        return [(this.expandedId = undefined)];
      } else {
        return [(this.expandedId = id)];
      }
    }
  }
};
</script>
<style src="../style.scss" lang="scss" />
